import { configureStore } from '@reduxjs/toolkit';

// Importing the reducers for each slice of the state
import authReducer from './auth/AuthSlice';
import contractReducer from './auth/ContractAuthSlice';
import MessageSlice from './chat/MessageSlice';
import BreadcrumbSlice from './breadcrumb/BreadcrumbSlice';

// Configuring the Redux store by combining the reducers
export const store = configureStore({
  reducer: {
    // State slice for user authentication
    auth: authReducer,
    // State slice for messages (e.g., chat messages)
    messageSlice: MessageSlice,
    // State slice for contract authentication
    contract: contractReducer,

    BreadcrumbSlice: BreadcrumbSlice
  },
});

export default store;
