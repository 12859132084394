import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";

import Themeroutes from "./routes/Router";
import Loader from "./components/sharedComponents/loader/Loader";
import 'src/assets/sharedAssets/scss/style.scss';

function App() {
  const routing = useRoutes(Themeroutes);


  return (
    <Suspense fallback={<Loader />}>
      <div>
        {routing}
      </div>
    </Suspense>
  );
}

export default App;
