// Import React lazy and a custom Loadable component
import { lazy } from "react";
import Loadable from "src/components/sharedComponents/loader/Loadable";

// Import Guards
import AuthGuard from "../utils/auth/AuthGuard";
import LoginGuard from "../utils/auth/LoginGuard";
import Role from "../utils/auth/Roles";
import Permission from "src/utils/auth/Permissions";
import ContractLoginGuard from "src/utils/contractAuth/ContractLoginGuard";
import ContractAuthGuard from "src/utils/contractAuth/ContractAuthGuard";

// Lazy-loaded components
const DemoSetup = Loadable(lazy(() => import("src/pages/crm/demo")));
const ChatBotCustomizer = Loadable(lazy(() => import("src/pages/crm/chatbotCustomizer")));
const AccountSetupStepper = Loadable(lazy(() => import("src/pages/crm/accountSetup")));
const ContractDetails = Loadable(lazy(() => import("src/pages/contract/ContractDetails")));
const ContractLayout = Loadable(lazy(() => import("src/layouts/contractLayouts/contractLayout")));
const ContractLogin = Loadable(lazy(() => import("src/pages/contract/auth/Login")));
const Templates = Loadable(lazy(() => import("src/pages/crm/template")));
const AddTemplate = Loadable(lazy(() => import("src/pages/crm/template/AddTemplate")));
const BroadcastNew = Loadable(lazy(() => import("src/pages/crm/broadcastNew")));
const AddBroadcastNew = Loadable(lazy(() => import("src/pages/crm/broadcastNew/AddBroadcast")));
const BroadcastDetailsNew = Loadable(lazy(() => import("src/pages/crm/broadcastNew/broadcastDetail")));
const AgencyDataCount = Loadable(lazy(() => import("src/pages/superAdminPages/agencyDataCount")));
const UpdateListing = Loadable(lazy(() => import("src/pages/crm/property/propertyComponents/UpdateProperty/UpdateListing")));

/**** Lazy-loaded components ****/
const Subscriptions = Loadable(lazy(() => import("src/pages/crm/auth/subscriptions")));
const SubscriptionsList = Loadable(lazy(() => import("src/pages/superAdminPages/subscription/Index.js")));
const PaymentConfirmation = Loadable(lazy(() => import("src/pages/crm/auth/subscriptions/PaymentConfirmation.jsx")));
const Transactions = Loadable(lazy(() => import("src/pages/superAdminPages/transactions/Index")));

/**** Layouts ****/
const FullLayout = Loadable(lazy(() => import("../layouts/crmLayouts/FullLayout")));
const PMSLayout = Loadable(lazy(() => import("../layouts/pmsLayouts/pmsMainLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/sharedLayouts/BlankLayout")));
const PublicLayout = Loadable(lazy(() => import("../layouts/sharedLayouts/PolicyLayout")));
const SettingLayout = Loadable(lazy(() => import("../layouts/crmLayouts/SettingLayout")));

/***** Pages *****/
const General = Loadable(lazy(() => import("src/pages/crm/dashboards")));

/***** Apps *****/
const LoginHistory = Loadable(lazy(() => import("src/pages/superAdminPages/loginHistory")));
const Todo = Loadable(lazy(() => import("src/pages/crm/todo/Todo")));
const Contacts = Loadable(lazy(() => import("src/pages/crm/contacts")));
const PropertyDetail = Loadable(lazy(() => import("src/pages/crm/property/PropertyDetail")));
const NewCalendarApp = Loadable(lazy(() => import("src/pages/crm/calendar")));
const TicketList = Loadable(lazy(() => import("src/pages/crm/ticket/TicketList")));
const TicketDetail = Loadable(lazy(() => import("src/pages/crm/ticket/TicketDetail")));
const BranchSettings = Loadable(lazy(() => import("src/pages/crm/settings/Branch")));
const ProfileSettings = Loadable(lazy(() => import("src/pages/crm/settings/Profile/Profile")));
const SubscriptionSettings = Loadable(lazy(() => import("src/pages/crm/settings/Subscription")));
const UsersDetails = Loadable(lazy(() => import("src/pages/crm/settings/Users")));
const ConfigurationSettings = Loadable(lazy(() => import("src/pages/crm/settings/Configuration")));
const ManageChat = Loadable(lazy(() => import("src/pages/superAdminPages/conversations/Index")));
const AgencyAccess = Loadable(lazy(() => import("src/pages/superAdminPages/agencyAccess/Index")));
const AgecnyList = Loadable(lazy(() => import("src/pages/superAdminPages/agencyList/Index")));
const AgecnyLeadList = Loadable(lazy(() => import("src/pages/superAdminPages/agencyLeadList/Index")));
const AgecnyChatList = Loadable(lazy(() => import("src/pages/superAdminPages/agencyChatList/Index")));

/***** Auth Pages *****/
const Error = Loadable(lazy(() => import("src/pages/crm/auth/Error")));
const LoginFormik = Loadable(lazy(() => import("src/pages/crm/auth/LoginFormik")));
const Maintanance = Loadable(lazy(() => import("src/pages/crm/auth/Maintanance")));
const RecoverPassword = Loadable(lazy(() => import("src/pages/crm/auth/RecoverPassword")));
const RecoverPwd = Loadable(lazy(() => import("src/pages/crm/auth/RecoverPwd")));
const TermsAndCondition = Loadable(lazy(() => import("src/pages/crm/policy/TermsAndConditions.jsx")));
const PrivacyPolicy = Loadable(lazy(() => import("src/pages/crm/policy/PrivacyPolicy.jsx")));
const UnsubscribeEmail = Loadable(lazy(() => import("src/pages/crm/unsubscribe")));
const LeadConfirmation = Loadable(lazy(() => import("src/pages/crm/leadConfirmation")));
const EmailConfirmation = Loadable(lazy(() => import("src/pages/crm/emailConfirmation")));
const LiveChat = Loadable(lazy(() => import("src/pages/crm/liveChat")));
const Channels = Loadable(lazy(() => import("src/pages/crm/connectChannels/channels")));
const ChatBotBuilder = Loadable(lazy(() => import("src/pages/crm/chatbotBuilder")));
const Bots = Loadable(lazy(() => import("src/pages/crm/bots")));
const Contract = Loadable(lazy(() => import("src/pages/contract")));
const CreateContract = Loadable(lazy(() => import("src/pages/contract/createContract")));
const ContractVerification = Loadable(lazy(() => import("src/pages/contract/verifyContract")));
const Success = Loadable(lazy(() => import("src/pages/contract/success")));
const PaymentFail = Loadable(lazy(() => import("src/pages/contract/failed")));
const GPS = Loadable(lazy(() => import("src/pages/crm/gps")));
const Property = Loadable(lazy(() => import("src/pages/crm/property")));
const RolePermissions = Loadable(lazy(() => import("src/pages/crm/rolePermissions")));
const AddCrmProperty = Loadable(lazy(() => import("src/pages/crm/property/propertyComponents/AddProperty/AddProperty")));

// PMS Routes
const PropertyList = Loadable(lazy(() => import('../pages/pms/property/propertyList')));
const PropertyDetails = Loadable(lazy(() => import('../pages/pms/property/propertyDetail')));
const AddProperty = Loadable(lazy(() => import('../pages/pms/property/addProperty')));
const EditProperty = Loadable(lazy(() => import('../pages/pms/property/editProperty')));

/*****Routes******/
const ThemeRoutes = [
  // Main routes with authentication
  {
    path: "/",
    element: (<AuthGuard> <FullLayout /> </AuthGuard>),
    children: [
      { path: "", name: "general", exact: true, element: (<General />) },
      { path: "dashboard", name: "general", exact: true, element: (<General />) },
      { path: "bot-theme-customizer/:botId", name: "Bot Styling", exact: true, element: (<Permission permission="ChatbotBuilder"> <ChatBotCustomizer /> </Permission>) },
      { path: "todo", name: "todo", exact: true, element: (<Permission permission="todo"> <Todo /> </Permission>) },
      { path: "manage-user-access", name: "manage user access", exact: true, element: (<Permission permission="permissionsManager"> <RolePermissions /> </Permission>) },
      { path: "livechat", name: "Live chat", exact: true, element: (<Permission permission="chat" action="read"> <LiveChat /> </Permission>) },
      { path: "contacts", name: "contacts", exact: true, element: (<Permission permission="contact"> <Contacts /> </Permission>) },
      { path: "calendar", name: "calendar", exact: true, element: (<Permission permission="calendar"> <NewCalendarApp /> </Permission>) },
      { path: "login-history", name: "login history", exact: true, element: (<Permission permission="agencyAccess"> <LoginHistory /> </Permission>) },
      { path: "agency-access", name: "agency access", exact: true, element: (<Permission permission="agencyAccess"> <AgencyAccess /> </Permission>) },
      { path: "agency-list", name: "agency details", exact: true, element: (<Permission permission="agencyAccess"> <AgecnyList /> </Permission>) },
      { path: "agency-data-count", name: "agency Data Count", exact: true, element: (<Permission permission="agencyAccess"> <AgencyDataCount /> </Permission>) },
      { path: "agency-lead-list", name: "agency lead list", exact: true, element: (<Permission permission="agencyAccess"> <AgecnyLeadList /> </Permission>) },
      { path: "agency-chat-list", name: "agency chat list", exact: true, element: (<Permission permission="agencyAccess"> <AgecnyChatList /> </Permission>) },
      { path: "conversations", name: "conversations", exact: true, element: (<Role roles={["superadmin"]}> <ManageChat /> </Role>) },
      { path: "transactions", name: "transactions", exact: true, element: (<Role roles={["superadmin"]} ><Transactions /> </Role>) },
      { path: "subscription-list", name: "subscriptions", exact: true, element: (<Role roles={["superadmin"]}> <SubscriptionsList /> </Role>) },
      { path: "leads", name: "leads", exact: true, element: (<Permission permission="lead"> <TicketList /> </Permission>) },
      { path: "leads/detail", name: "lead detail", exact: true, element: (<Permission permission="lead"> <TicketDetail /> </Permission>) },
      { path: "/channels", name: "channels", exact: true, element: (<Permission permission="channel"> <Channels /> </Permission>) },
      { path: "/property", name: "property", exact: true, element: (<Permission permission="property"> <Property /> </Permission>) },
      { path: "/property/AddProperty", name: "property", exact: true, element: (<Permission permission="property"> <AddCrmProperty /> </Permission>) },
      { path: "/property/Update-listing", name: "property", exact: true, element: (<Permission permission="property"> <UpdateListing /> </Permission>) },
      { path: "/property/propertyDetail/:id", name: "property-detail", exact: true, element: (<Permission permission="property"> <PropertyDetail /> </Permission>) },
      { path: "bots", name: "Chat bot", exact: true, element: (<Permission permission="chatbot"> <Bots /> </Permission>) },
      {
        path: "settings", name: "Setting", element: (<SettingLayout />), children: [
          { path: "branch-details", name: "branch details", exact: true, element: (<Permission permission="branch"> <BranchSettings /> </Permission>) },
          { path: "channels", name: "channel", exact: true, element: (<Permission permission="channel"> <Channels /> </Permission>) },
          { path: "my-profile", name: "profile", exact: true, element: (<Role roles={["superadmin", "companyadmin", "companymanager", "agent"]}> <ProfileSettings settings={true} /> </Role>) },
          { path: "subscription", name: "subscription", exact: true, element: (<Permission permission="subscription"> <SubscriptionSettings /> </Permission>) },
          { path: "users-details", name: "subscription", exact: true, element: (<Permission permission="employee"> <UsersDetails /> </Permission>) },
          { path: "configuration", name: "configuration", exact: true, element: (<Permission permission="configuration"> <ConfigurationSettings /> </Permission>) },
        ]
      },
      { path: "chatbot-builder", name: "Chatbot Builder", exact: true, element: (<Permission permission="chatbot"> <ChatBotBuilder /> </Permission>) },
      { path: "chatbot/compare", name: "Chatbot", exact: true, element: (<Permission permission="chatbot"> <ChatBotBuilder /> </Permission>) },
      { path: "chatbot/restore", name: "Chatbot", exact: true, element: (<Permission permission="chatbot"> <ChatBotBuilder /> </Permission>) },
      { path: "setup-demo", name: "Demo Setup", exact: true, element: (<Role roles={["superadmin", "companyadmin", "companymanager", "agent"]}> <DemoSetup /> </Role>) },
      { path: "templates", name: "Template Message", exact: true, element: (<Permission permission="whatsappTemplate"> <Templates /> </Permission>) },
      { path: "templates/add", name: "Add Template Message", exact: true, element: (<Permission permission="whatsappTemplate"> <AddTemplate /> </Permission>) },
      { path: "templates/edit/:id", name: "Add Template Message", exact: true, element: (<Permission permission="whatsappTemplate"> <AddTemplate /> </Permission>) },
      { path: "broadcast", name: "Broadcast", exact: true, element: (<Permission permission="broadcast"> <BroadcastNew /> </Permission>) },
      { path: "broadcast/add", name: "Add Broadcast Message", exact: true, element: (<Permission permission="broadcast"> <AddBroadcastNew /> </Permission>) },
      { path: "broadcast/detail/:id", name: "Broadcast Detail", exact: true, element: (<Permission permission="broadcast"> <BroadcastDetailsNew /> </Permission>) },
    ],
  },

  {
    path: "pms",
    element: (<AuthGuard> <PMSLayout /> </AuthGuard>),
    children: [
      { path: "", name: "general", exact: true, element: (<Permission permission="Dashboard"> <General /> </Permission>) },
      { path: "dashboard", name: "general", exact: true, element: (<Permission permission="Dashboard"> <General /> </Permission>) },
      { path: "property", name: "Property", exact: true, element: <PropertyList /> },
      {
        path: 'property', children: [
          { path: '', element: <PropertyList /> },
          { path: 'create-property', element: <AddProperty /> },
          { path: ':id', element: <PropertyDetails /> },
          { path: ':id/edit', element: <EditProperty /> }
        ]
      },
    ],
  },

  // Auth routes
  {
    path: "auth",
    element: (<LoginGuard> <BlankLayout /> </LoginGuard>),
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <LoginFormik /> },
      { path: "maintanance", element: <Maintanance /> },
      { path: "recoverpwd", element: <RecoverPassword /> },
      { path: "forgotPwd", element: <RecoverPwd /> },
    ],
  },

  // eSignature routes
  {
    path: "eSignature",
    element: (<BlankLayout />),
    children: [
      { path: "login", element: (<ContractLoginGuard> <ContractLogin /> </ContractLoginGuard>) },
      {
        path: "dashboard", element: <ContractAuthGuard> <ContractLayout /> </ContractAuthGuard>, children: [
          { path: "contract", name: "contract", exact: true, element: (<Contract />) },
          { path: "/eSignature/dashboard/create-contract", name: "create-contract", exact: true, element: (<CreateContract />) },
          { path: "/eSignature/dashboard/contract-details/:id", name: "Contract details", exact: true, element: (<ContractDetails />) },
        ]
      },
    ],
  },

  // Miscellaneous routes
  { path: "gps", element: <GPS /> },
  { path: "unsubscribe", element: <UnsubscribeEmail /> },
  { path: "confirm-lead", element: <LeadConfirmation /> },
  { path: "email-verification", element: <EmailConfirmation /> },
  { path: "contract-verification", element: <ContractVerification /> },
  { path: "subscriptions/:plan", element: <Subscriptions /> },
  { path: "return", element: <PaymentConfirmation /> },
  { path: "success", element: <Success /> },
  { path: "failed", element: <PaymentFail /> },

  // Account setup stepper
  {
    path: "account-setup",
    name: "account setup stepper",
    exact: true,
    element: (<AuthGuard> <Permission permission="accountSetup"> <AccountSetupStepper /> </Permission> </AuthGuard>),
  },

  // Public routes
  {
    path: "policy",
    element: <PublicLayout />,
    children: [
      { path: "terms-of-service", element: <TermsAndCondition /> },
      { path: "privacypolicy", element: <PrivacyPolicy /> },
    ],
  },

  // Catch-all error route
  { path: "*", element: <Error /> },
];


export default ThemeRoutes;
