import { createSlice } from "@reduxjs/toolkit";

const breadcrumbsSlice = createSlice({
    name: "breadcrumbs",
    initialState: { breadcrumbs: [] },
    reducers: {
        addBreadcrumb: (state, action) => {
            // Check if breadcrumb with same name exists
            const existingIndex = state.breadcrumbs.findIndex(
                (breadcrumb) => breadcrumb.name === action.payload.name
            );

            if (existingIndex !== -1) {
                // Update existing breadcrumb if it exists
                state.breadcrumbs[existingIndex] = {
                    ...state.breadcrumbs[existingIndex],
                    ...action.payload
                };
            } else {
                // Add new breadcrumb
                state.breadcrumbs = [...state.breadcrumbs, action.payload];
            }
        },
        removeBreadcrumb: (state, action) => {
            state.breadcrumbs = state.breadcrumbs.filter(
                (breadcrumb) => breadcrumb.name !== action.payload.name
            );
        },
        clearBreadcrumbs: (state) => {
            state.breadcrumbs = [];
        },
        setBreadcrumbs: (state, action) => {
            state.breadcrumbs = action.payload;
        },
        updateBreadcrumbPath: (state, action) => {
            const { name, path } = action.payload;
            const breadcrumb = state.breadcrumbs.find(b => b.name === name);
            if (breadcrumb) {
                breadcrumb.path = path;
            }
        }
    },
});

export const { 
    addBreadcrumb, 
    removeBreadcrumb, 
    clearBreadcrumbs, 
    setBreadcrumbs,
    updateBreadcrumbPath 
} = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;
