import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Roles } from 'src/constants/sharedConstants/roles';

const LoginGuard = ({ children }) => {
  // Accessing the current location and search parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Retrieving the redirectUrl from query parameters
  const redirectUrl = searchParams.get('redirectUrl');

  // Getting the auth state from the Redux store
  const auth = useSelector((state) => state.auth);

  // If the user is logged in
  if (auth?.isLoggedin) {
    // If a redirectUrl is present, navigate to it
    if (redirectUrl) {
      return <Navigate to={redirectUrl} />;
    } else {
      // If user is a SUPER_ADMIN, redirect to /agency-list
      if (auth.user.role === Roles.SUPER_ADMIN) {
        return <Navigate to="/agency-list" />;
      }
      // If user is not SUPER_ADMIN, redirect to /dashboard
      return <Navigate to="/dashboard" />;
    }
  }

  // If not logged in, render children or Outlet (for nested routes)
  return children ? children : <Outlet />;
};

// Defining the expected prop types for the component
LoginGuard.propTypes = {
  children: PropTypes.node,
};

export default LoginGuard;
