import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// Initial state with data retrieved from localStorage and cookies
const initialState = {
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
  token: null,
  isLoggedin: Cookies.get("isLoggedin") || null,
  branchList: [],
  selectedBranch: "",
  updateBranch: "",
  notificationsList:[]
};

const authSlice = createSlice({
  name: "auth",  // Name of the slice
  initialState,  // Initial state for the slice
  reducers: {
    // Set user credentials after successful login
    setCredentials: (state, action) => {
      const usrInfo = action.payload.data;

      // User object with relevant fields
      const usrObj = {
        name: usrInfo.name,
        profilePic: usrInfo.avatar,
        _id: usrInfo._id,
        email: usrInfo.email,
        role: usrInfo.userType,
        agency: usrInfo.agency,
        branch: usrInfo.branch,
        branchName: usrInfo.branchName,
        address: usrInfo?.address,
        companyName: usrInfo?.companyName,
        departmentType: usrInfo?.departmentType,
        departmentRole: usrInfo?.departmentRole,
        userCategory: usrInfo?.userCategory,
        agencyCategory: usrInfo?.agencyCategory,
        openingHours: usrInfo?.openingHours,
        branchCategory: usrInfo?.branchCategory,
        roleId: usrInfo?.role,
        permissions: usrInfo?.permissions,
        accountSetupFormStep: usrInfo?.accountSetupFormStep,
        googleAccessToken: usrInfo?.googleAccessToken,
        googleAccessExpiryIn: usrInfo?.googleAccessExpiryIn,
        googleIsActive: usrInfo?.googleIsActive,
        googleSessionExpired: usrInfo?.googleSessionExpired,
        outlookAccessToken: usrInfo.outlookAccessToken,
        outlookAccessExpiryIn: usrInfo.outlookAccessExpiryIn,
        outlookIsActive: usrInfo.outlookIsActive,
        outlookSessionExpired: usrInfo.outlookSessionExpired
      };

      // Store user info and set login status
      state.user = usrObj;
      state.isLoggedin = true;

      // Persist user info and login status in localStorage and cookies
      localStorage.setItem("user", JSON.stringify(usrObj));
      Cookies.set("isLoggedin", true, { expires: 7 });
    },

    // Update user information
    updateUser: (state, action) => {
      const usrInfo = action.payload.data;

      const usrObj = {
        name: usrInfo.name,
        profilePic: usrInfo.avatar || usrInfo.profilePic,
        id: usrInfo.id,
        _id: usrInfo._id,
        email: usrInfo.email,
        agency: usrInfo.agency,
        branch: usrInfo.branch,
        branchName: usrInfo.branchName,
        role: usrInfo.role,
        address: usrInfo?.address,
        companyName: usrInfo?.companyName,
        departmentType: usrInfo?.departmentType,
        departmentRole: usrInfo?.departmentRole,
        userCategory: usrInfo?.userCategory,
        agencyCategory: usrInfo?.agencyCategory,
        openingHours: usrInfo?.openingHours,
        branchCategory: usrInfo?.branchCategory,
        roleId: usrInfo?.roleId,
        permissions: usrInfo?.permissions,
        accountSetupFormStep: usrInfo?.accountSetupFormStep,
        googleAccessToken: usrInfo?.googleAccessToken,
        googleAccessExpiryIn: usrInfo?.googleAccessExpiryIn,
        googleIsActive: usrInfo?.googleIsActive,
        googleSessionExpired: usrInfo?.googleSessionExpired,
        outlookAccessToken: usrInfo.outlookAccessToken,
        outlookAccessExpiryIn: usrInfo.outlookAccessExpiryIn,
        outlookIsActive: usrInfo.outlookIsActive,
        outlookSessionExpired: usrInfo.outlookSessionExpired
      };

      // Update the state with the new user data
      state.user = usrObj;

      // If a new token is provided, update the token
      if (action.payload.token) {
        state.token = action.payload.token;
      }

      // Store updated user data in localStorage
      localStorage.setItem("user", JSON.stringify(usrObj));
    },

    // Update token in the state
    updateToken: (state, action) => {
      state.token = action.payload.token;
    },

    // Update the authorization status of the user
    updateAuthorizedStatus: (state, action) => {
      const user = {
        ...state.user,
        isAuthorized: action.payload.isAuthorized,
      };
      state.user = user;
    },

    // Update the login status
    updateIsLoggedIn: (state, action) => {
      state.isLoggedin = action.payload.isLoggedin;
    },

    // Logout the user and clear all user-related data
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isLoggedin = false;
      localStorage.clear();
      // localStorage?.removeItem('switch');
      // localStorage?.removeItem('user');

      // Remove all client based cookies
      Object.keys(Cookies.get()).forEach((cookieName) => Cookies.remove(cookieName));
    },

    // Save the branch list to the state
    saveBranchList: (state, action) => {
      if (action.payload) {
        state.branchList = action.payload;
      } else {
        state.branchList = [];
      }
    },

    // Update the selected branch in the state
    updateBranch: (state, action) => {
      if (action.payload) {
        state.selectedBranch = action.payload;
      } else {
        state.selectedBranch = "";
      }
    },

    // Update the account setup form step
    updateFormStep: (state, action) => {
      if (action.payload) {
        state.user.accountSetupFormStep = action.payload;
      } else {
        state.user.accountSetupFormStep = 1;
      }
    },

// Update notification list
    updateNotificationList: (state, action) => {
      if (action.payload) {
        state.notificationsList = action.payload;
      } else {
        state.notificationsList = [];
      }
    },
  },
});

// Selectors for accessing parts of the state
export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;

// Exporting actions to be used in components
export const {
  setCredentials,
  updateUser,
  updateToken,
  updateAuthorizedStatus,
  updateIsLoggedIn,
  logout,
  saveBranchList,
  updateBranch,
  updateFormStep,
  updateNotificationList
} = authSlice.actions;

// Export the reducer to be used in the store
export default authSlice.reducer;
