import Cookies from "js-cookie";
import { createSlice } from "@reduxjs/toolkit";

// Initial state for the contract authentication slice
const initialState = {
  // Retrieving contract user information from localStorage if available
  contractUser: localStorage.getItem("contractUser")
    ? JSON.parse(localStorage.getItem("contractUser"))
    : null,
  // Initial contract token set to null
  contractToken: null,
  // Retrieving the login status from cookies
  isLoggedin: Cookies.get("isLoggedin") || null,
};

const contractAuthSlice = createSlice({
  name: "contractAuth", // Name of the slice
  initialState, // Initial state for the slice
  reducers: {
    // Reducer to set the user's credentials upon successful login
    setCredentials: (state, action) => {
      const usrInfo = action.payload.data;
      // Creating a user object to store in the state
      const usrObj = {
        name: usrInfo.name,
        profilePic: usrInfo.avatar,
        _id: usrInfo._id,
        email: usrInfo.email,
        role: usrInfo.userType,
      };

      // Updating the state with the user object and setting login status
      state.contractUser = usrObj;
      state.isLoggedin = true;
      // Storing user information in localStorage and setting login cookie
      localStorage.setItem("contractUser", JSON.stringify(usrObj));
      Cookies.set("isLoggedin", true, { expires: 7 });
    },

    // Reducer to update user information
    updateUser: (state, action) => {
      const usrInfo = action.payload.data;

      // Creating a user object with updated information
      const usrObj = {
        name: usrInfo.name,
        profilePic: usrInfo.avatar || usrInfo.profilePic,
        id: usrInfo.id,
        _id: usrInfo._id,
        email: usrInfo.email,
      };

      // Updating the state with the new user object
      state.contractUser = usrObj;

      // If a new contract token is provided, update the token in the state
      if (action.payload.contractToken) {
        state.contractToken = action.payload.contractToken;
      }

      // Updating localStorage with the new user information
      localStorage.setItem("contractUser", JSON.stringify(usrObj));
    },

    // Reducer to update the login status
    updateIsLoggedIn: (state, action) => {
      state.isLoggedin = action.payload.isLoggedin;
    },

    // Reducer to log out the user and clear state, localStorage, and cookies
    logout: (state) => {
      // Resetting state values to initial state
      state.contractUser = null;
      state.contractToken = null;
      state.isLoggedin = false;

      // Clearing localStorage and removing all cookies
      localStorage.clear();
      // localStorage?.removeItem('switch');
      // localStorage?.removeItem('contractUser');
      Object.keys(Cookies.get()).forEach((cookieName) =>
        Cookies.remove(cookieName)
      );
    },
  },
});

// Selectors to retrieve specific pieces of the state
export const selectContractUser = (state) => state.auth.contractUser;
export const selectContractToken = (state) => state.auth.contractToken;

// Exporting actions for usage in components
export const { setCredentials, updateUser, logout } = contractAuthSlice.actions;

// Exporting the reducer to be used in the store configuration
export default contractAuthSlice.reducer;
