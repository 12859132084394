import axios from "axios";
import Cookies from "js-cookie";

import { API_BASE_URL, LOGOUTMESSAGE } from "src/constants/sharedConstants/constants";
import { updateAuthorizedStatus } from "src/store/auth/AuthSlice";
import store from "src/store/Store";

// Creating an Axios instance with a base URL and default params and headers
const Axios = axios.create({
  baseURL: API_BASE_URL,
  params: {
    apporigin: "web", // Default param indicating the app origin
  },
  headers: {
    source: "web", // Default header indicating the source as web
  },
});

// Adding a request interceptor to include credentials with each request
Axios.interceptors.request.use((config) => {
  config.withCredentials = true; // This ensures cookies are sent with cross-origin requests
  return config;
});

// Adding a response interceptor to handle errors globally
Axios.interceptors.response.use(
  (response) => response, // If the response is successful, return it as is
  (error) => {
    // Handling 401 Unauthorized errors
    if (error.response.status === 401) {
      // Clear local storage and cookies upon unauthorized access
      localStorage.clear();
      Object.keys(Cookies.get()).forEach((cookieName) =>
        Cookies.remove(cookieName)
      );

      // Dispatch an action to update the authorization status in the Redux store
      store.dispatch(updateAuthorizedStatus({ isAuthorized: false }));
      return Promise.reject(LOGOUTMESSAGE);
    }
    // Return a meaningful error message
    return Promise.reject(
      error.response.data.error ||
      error.response.data.message ||
      "Something went wrong"
    );
  }
);

export default Axios;
