import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Permissions = ({ children, permission, action }) => {
  const userObj = useSelector((state) => state.auth.user);
  const userPermissions = userObj?.permissions?.map(el => el?.module) ?? [];  // Default to an empty array if undefined

  // If no permission is required or the user is a companyadmin, render the children
  if (!permission || typeof permission === "undefined") {
    return children;
  }

  // Check if the user has the required permission
  const hasPermission = userPermissions.includes(permission);
  if (action) {
    const hasAction = userObj?.permissions.find(el => el.module === permission)?.allowedActions?.includes(action);
    return !(hasPermission && hasAction) ? <Navigate to="/nopermission" /> : children;
  }

  return hasPermission ? children : <Navigate to="/nopermission" />;

};

export default Permissions;
